import React, { Children, cloneElement, isValidElement } from 'react';

import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Content = ({ children, gutters = true, className = '', loading }) => {
	const childrenWithProps = Children.map(children, (child) => {
		// Checking isValidElement is the safe way and avoids a TS error too.
		if (isValidElement(child) && child?.type?.displayName) {
			return cloneElement(child, { isCard: true });
		}

		return child;
	});

	return (
		<div
			className={`${styles.cardContent} ${loading ? styles.cardContentLoading : ''} ${
				!gutters ? styles.cardContentNoGutters : ''
			} ${className}`}
		>
			{loading && (
				<div className={styles.cardContentLoader}>
					here
					<FontAwesomeIcon icon={['fas', 'circle-notch']} spin />
				</div>
			)}
			{!loading && childrenWithProps}
		</div>
	);
};

export default Content;
