import React, { Children, isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { subcomponent } from '@helpers';

// card sub components
import Image from './Image';
import Title from './Title';
import Content from './Content';
import Actions from './Actions';
import List from './List';
import Position from './Position';

import styles from './styles.module.scss';
import { useLocation } from '@reach/router';


const Card = ({
	onClick = null,
	hoverable = false,
	depth = 0,
	children = null,
	center = false,
	className,
	inverse = false,
	disabled = false,
	loading = false,
	disableBackground,
}) => {
	const cardTitle = subcomponent(children, Title, true);
	const cardImage = subcomponent(children, Image, true);
	const cardContent = subcomponent(children, Content, true, { loading });
	const cardActions = subcomponent(children, Actions, true);
	const cardList = subcomponent(children, List, false, { loading });
	const cardTopLeft = subcomponent(children, Position.Top.Left, true);
	const cardTopRight = subcomponent(children, Position.Top.Right, true);
	const cardBottomLeft = subcomponent(children, Position.Bottom.Left, true);
	const cardBottomRight = subcomponent(children, Position.Bottom.Right, true);
	const depthClass =
		(depth === 0 && styles.cardDepthNone) ||
		(depth === 1 && styles.cardDepthBottom) ||
		(depth === 2 && styles.cardDepthMiddle) ||
		(depth === 3 && styles.cardDepthTop) ||
		'';
	const centerClass = center ? styles.cardCenter : '';
	const inverseClass = inverse ? styles.cardInverse : '';
	const hoverableClass = !disabled && (hoverable || onClick) ? styles.cardHoverable : '';
	const disabledClass = disabled ? styles.cardDisabled : '';
	const loadingClass = loading ? styles.cardIsLoading : '';

	const location = useLocation();

	const isContact = location?.pathname?.includes('contact')

	return (
		<div
			className={`${styles.card} ${loadingClass} ${hoverableClass} ${disabledClass} ${centerClass} ${depthClass} ${inverseClass} ${className} ${isContact ? styles.disableBackground : ''}`}
			onClick={disabled ? undefined : onClick}
			tabIndex={!disabled && (hoverable || onClick) ? 0 : null}
			onKeyPress={(e) => (!disabled && onClick && (e.keyCode || e.charCode) === 13 ? onClick() : undefined)}
		>
			{cardImage}
			{cardTopLeft || cardTopRight ? (
				<div className={`${styles.cardPositionEnd} ${styles.cardPositionTop}`}>
					{cardTopLeft} {cardTopRight}
				</div>
			) : null}
			{cardTitle}
			{cardContent}
			{cardList}
			{cardActions}
			{cardBottomLeft || cardBottomRight ? (
				<div className={`${styles.cardPositionEnd} ${styles.cardPositionBottom}`}>
					{cardBottomLeft} {cardBottomRight}
				</div>
			) : null}
		</div>
	);
};

Card.propTypes = {
	onClick: PropTypes.func,
	hoverable: PropTypes.bool,
	depth: PropTypes.number,
	center: PropTypes.bool,
	disableBackground: PropTypes.bool
};

Card.Image = Image;
Card.Title = Title;
Card.Content = Content;
Card.Actions = Actions;
Card.List = List;
Card.Position = Position;

export default Card;
